<template>
    <uw-page :title="!loading ? year + '年' + month + '月 - 效率分析' : '效率分析'">
        
        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button @click="search()" type="success">刷新</el-button>
            <el-button type="primary" @click="$refs.xTable.print()" >打印</el-button>
            <el-button type="primary" @click="$refs.xTable.openExport()" >导出</el-button>
        </template>

        <!-- 检索 -->
        <template slot="search">
            <el-form-item label="用户查询">
                <users-select v-model="post.search.users_id" multiple @onChange="search()" />
            </el-form-item>
            <el-form-item label="日期查询">
                <el-date-picker v-model="post.search.date" clearable @change="search()" type="month" value-format="yyyy-MM" />
            </el-form-item>
        </template>

        <vxe-table
            ref="xTable"
            height="100%"
            :data="table"
            :loading="loading"
            :export-config="{}"
            :print-config="{}"
            @sort-change="tableSortChange"
            :cell-style="tableCellStyle"
            :header-cell-style="tableheaderCellStyle">

            <!-- 用户 -->
            <vxe-column min-width="120" field="name" title="成员" fixed="left">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.users?.avatar">{{ row.users?.name[0] }}</el-avatar>
                        <el-link type="primary" @click="$refs.employeeDrawer.Open(row.users_id)">{{  row.users?.name }}</el-link>
                    </div>
                </template>
            </vxe-column>

            <!-- 任务 -->
            <vxe-column width="100" field="task_day_consume" title="任务已耗" align="right" sortable>
                <template #default="{ row }">
                    {{ row.task_day_consume }} / D
                </template>
            </vxe-column>
            <vxe-column width="100" field="task_day_predict" title="任务预估" align="right" sortable>
                <template #default="{ row }">
                    {{ row.task_day_predict }} / P
                </template>
            </vxe-column>
            <vxe-column width="100" field="task_count" title="参与任务" align="right" sortable>
                <template #default="{ row }">
                    {{ row.task_count }} / 个
                </template>
            </vxe-column>
            <vxe-column width="100" field="task_day_efficiency" title="任务效率" align="right" sortable>
                <template #default="{ row }">
                    {{ row.task_day_efficiency }} / %
                </template>
            </vxe-column>

            <!-- 工单 -->
            <vxe-column width="100" field="wo_day_consume" title="工单已耗" align="right" sortable>
                <template #default="{ row }">
                    {{ row.wo_day_consume }} / D
                </template>
            </vxe-column>
            <vxe-column width="100" field="wo_day_predict" title="工单预估" align="right" sortable>
                <template #default="{ row }">
                    {{ row.wo_day_predict }} / P
                </template>
            </vxe-column>
            <vxe-column width="100" field="wo_count" title="参与工单" align="right" sortable>
                <template #default="{ row }">
                    {{ row.wo_count }} / 个
                </template>
            </vxe-column>
            <vxe-column width="100" field="wo_day_efficiency" title="工单效率" align="right" sortable>
                <template #default="{ row }">
                    {{ row.wo_day_efficiency }} / %
                </template>
            </vxe-column>

            <!-- 合计 -->
            <vxe-column width="100" field="sum_day_consume" title="合计已耗" align="right" sortable>
                <template #default="{ row }">
                    {{ row.sum_day_consume }} / D
                </template>
            </vxe-column>
            <vxe-column width="100" field="sum_day_predict" title="合计预估" align="right" sortable>
                <template #default="{ row }">
                    {{ row.sum_day_predict }} / P
                </template>
            </vxe-column>
            <vxe-column width="100" field="sum_day_efficiency" title="合计效率" align="right" sortable>
                <template #default="{ row }">
                    {{ row.sum_day_efficiency }} / %
                </template>
            </vxe-column>
            <vxe-column width="60" title="明细" align="center">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" @click="$refs.workingDrawer.open({ users_id: row.users_id, year: year, month: month })">查看</el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <pm-working-drawer ref="workingDrawer" />
        <hrm-employee-drawer ref="employeeDrawer" />

    </uw-page>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            
            table: [],
            month: null,
            year: null,
            
            // 检索
            post: {
                search: {
                    users_id: null,
                    date: null,
                },
                order: {
                    method: 'desc',
                    column: 'id'
                },
                append: ['users']
            }
            
        }
    },

    mounted () {
        this.search()
    },

    methods: {

        // 查询数据
        search()
        {
            this.loading = true
            this.$http.post('/9api/pm/working/search-month', this.post).then((rps) => {
                this.table = rps.data.table
                this.month = rps.data.month
                this.year = rps.data.year
                this.loading = false
            })
        },

        // 表格（排序）
        tableSortChange(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.search()
        },

        // 表格（列样式）
        tableCellStyle({ column })
        {
            if (['task_count', 'task_day_consume', 'task_day_predict', 'task_day_efficiency'].includes(column.field))
            {
                return { color: '#0027ff' }
            }
            if (['wo_count', 'wo_day_consume', 'wo_day_predict', 'wo_day_efficiency'].includes(column.field))
            {
                return { color: '#ff3d00' }
            }
            if (['sum_day_consume', 'sum_day_predict', 'sum_day_efficiency'].includes(column.field))
            {
                return { color: '#da00ff' }
            }
        },

        // 表格（表头样式）
        tableheaderCellStyle({ column })
        {
            if (['task_count', 'task_day_consume', 'task_day_predict', 'task_day_efficiency'].includes(column.field))
            {
                return { color: '#0027ff' }
            }
            if (['wo_count', 'wo_day_consume', 'wo_day_predict', 'wo_day_efficiency'].includes(column.field))
            {
                return { color: '#ff3d00' }
            }
            if (['sum_day_consume', 'sum_day_predict', 'sum_day_efficiency'].includes(column.field))
            {
                return { color: '#da00ff' }
            }
        }

    }
}
</script>